// Thanks to https://usehooks.com/useAuth/

import { API, Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { UserGroup } from 'shared/enums'
import { base64url } from 'shared/helper/base64url'
import isDev from 'shared/helper/isDev'
import { clearLocalStorage } from 'shared/helper/localStorage'
import useConnectApi from './useConnectApi'
import { useSoftphone } from './useSoftphone'
import { SOFTPHONE_USER_GROUPS } from './useUserPermission'

const CONNECT_ENABLED = process.env.REACT_APP_CONNECT_ENABLED
const CONNECT_DOMAIN = process.env.REACT_APP_CONNECT_DOMAIN

// TODO: refactor AuthContext class with proper types
export class AuthContext {
	public userData?: UserData
	public signin?: any
	public signout?: any
	public sendPasswordResetEmail?: any
	public confirmPasswordReset?: any
	public completeNewPassword?: any
	public getTOTPSetupCode?: any
	public verifyTOTPSetup?: any
	public setupWebAuth?: any
	public confirmSignin?: any
	public confirmWebAuth?: any
	public createWebAuthCredentials?: any

	/**
	 * only needed to prevent linting errors. there is no method for that at the moment.
	 * this method use used in other projects, which share the same components
	 */
	public sendChallengeAnswer?: any
	public userInitialized?: boolean
	public connectInitialized?: boolean
	public webAuthSetupAllowed?: boolean

	constructor(
		userData: UserData,
		signin: any,
		signout: any,
		sendPasswordResetEmail: any,
		confirmPasswordReset: any,
		completeNewPassword: any,
		getTOTPSetupCode: any,
		verifyTOTPSetup: any,
		setupWebAuth: any,
		confirmSignin: any,
		confirmWebAuth: any,
		createWebAuthCredentials: any,
		connectInitialized: boolean,
		webAuthSetupAllowed: boolean,
		userInitialized: boolean
	) {
		this.userData = userData
		this.signin = signin
		this.signout = signout
		this.sendPasswordResetEmail = sendPasswordResetEmail
		this.confirmPasswordReset = confirmPasswordReset
		this.completeNewPassword = completeNewPassword
		this.getTOTPSetupCode = getTOTPSetupCode
		this.verifyTOTPSetup = verifyTOTPSetup
		this.setupWebAuth = setupWebAuth
		this.confirmSignin = confirmSignin
		this.confirmWebAuth = confirmWebAuth
		this.createWebAuthCredentials = createWebAuthCredentials
		this.userInitialized = userInitialized
		this.connectInitialized = connectInitialized
		this.webAuthSetupAllowed = webAuthSetupAllowed
	}
}

export interface UserData {
	email: string
	'cognito:username': string
	'cognito:groups': UserGroup[]
	groups: UserGroup[]
	given_name: string
	name: string
	sub: string
	'custom:publicKeyCred'?: string
}

export interface AuthError {
	successful: boolean
	code: string
	name: string
	message: string
}

export enum ErrorType {
	passwordResetRequired = 'PasswordResetRequiredException',
	newPasswordRequired = 'NewPasswordRequired',
	passwordMismatch = 'PasswordMismatch',
	userNotFound = 'UserNotFoundException',
	limitExceeded = 'LimitExceededException',
	notAString = 'SerializationException',
	codeMismatch = 'CodeMismatchException',
	enableSoftwareTokenMFAException = 'EnableSoftwareTokenMFAException',
	invalidParameter = 'InvalidParameterException',
	sessionInvalid = 'SessionInvalid',
	mfaSetup = 'MfaSetup',
	mfa = 'Mfa',
	webAuthSetup = 'WebAuthSetup',
	webAuthSetupMissing = 'WebAuthSetupMissing',
	userLambdaValidationException = 'UserLambdaValidationException',
	notAuthorizedException = 'NotAuthorizedException',
}

export type ConnectCredentialsResponse = {
	credentials: string
	destination: string
}

export interface UserCredentials {
	username: string
	password: string
}

const authContext = createContext<AuthContext>({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
	const auth: any = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}

Auth.configure({
	authenticationFlowType: 'CUSTOM_AUTH',
})

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	// const [user, setUser] = useState<UserData | undefined>()
	const userData = useRef<UserData | undefined>()
	const cognitoUserObject = useRef<any>()

	// just used to force a re-render
	const [userInitialized, setUserInitialized] = useState<boolean>(false)
	const [connectInitialized, setConnectInitialized] = useState<boolean>(false)
	const [webAuthSetupAllowed, setWebAuthSetupAllowed] = useState<boolean>(false)
	const { getAuthDetails } = useConnectApi()
	const { handleLogout: logoutFromConnect } = useSoftphone()

	// do not use useUserPermissionHook as it does not trigger a re-render
	const canUseSoftphone = useMemo(() => {
		if (userInitialized) {
			return SOFTPHONE_USER_GROUPS.some((group) => userData?.current?.groups.includes(group))
		}

		return false
	}, [userInitialized])

	const signin = async (credentials: UserCredentials): Promise<boolean | AuthError> => {
		// if (isDev()) {
		// 	setUser(devUserData)

		// 	localStorage.setItem('devUserData', JSON.stringify(devUserData))

		// 	return true
		// }

		try {
			const user = await Auth.signIn(credentials)

			if ('NEW_PASSWORD_REQUIRED' === user.challengeName) {
				const error = { code: ErrorType.newPasswordRequired, successful: false } as AuthError
				cognitoUserObject.current = user

				return error
			}

			if ('MFA_SETUP' === user.challengeName) {
				const error = { code: ErrorType.mfaSetup, successful: false } as AuthError
				cognitoUserObject.current = user

				return error
			}

			if ('SOFTWARE_TOKEN_MFA' === user.challengeName) {
				const error = { code: ErrorType.mfa, successful: false } as AuthError
				cognitoUserObject.current = user

				return error
			}

			if ('CUSTOM_CHALLENGE' === user.challengeName) {
				cognitoUserObject.current = user

				userData.current = user

				switch (user.challengeParam.challengeType) {
					case 'WEB_AUTH_SETUP_MISSING': {
						const error = { code: ErrorType.webAuthSetupMissing, successful: false } as AuthError

						return error
					}

					case 'WEB_AUTH': {
						const loginStatus = await confirmWebAuth()

						if (true === loginStatus) {
							return setUserDataAfterAuthentication()
						}

						return loginStatus
					}

					default: {
						const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

						return error
					}
				}
			}

			return setUserDataAfterAuthentication()
		} catch (e: any) {
			console.log({ e })
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const signout = async () => {
		try {
			await Auth.signOut({ global: true })
		} finally {
			Object.keys(Cookies.get()).forEach((cookieName) => {
				/**
				 * clear cookies from domain with an without leading dot,
				 * to make sure they are deleted in all browsers
				 */
				const neededAttributes = {
					path: '/',
					domain: `.${process.env.REACT_APP_COOKIE_DOMAIN}`,
				}
				Cookies.remove(cookieName, neededAttributes)

				neededAttributes.domain = String(process.env.REACT_APP_COOKIE_DOMAIN)
				Cookies.remove(cookieName, neededAttributes)
			})

			if (isDev()) {
				clearLocalStorage()
			}

			if (connectInitialized) {
				logoutFromConnect()
			}

			userData.current = undefined
			setUserInitialized(false)
			setConnectInitialized(false)
		}
	}

	const confirmWebAuth = async () => {
		try {
			if (undefined === cognitoUserObject.current) {
				const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

				return error
			}

			const { challenge, credId } = cognitoUserObject.current.challengeParam

			const signinOptions: PublicKeyCredentialRequestOptions = {
				challenge: base64url.decode(challenge),
				timeout: 1800000,
				rpId: window.location.hostname,
				userVerification: 'preferred',
				allowCredentials: [
					{
						id: base64url.decode(credId),
						type: 'public-key',
						transports: ['ble', 'nfc', 'usb', 'internal'],
					},
				],
			}

			// get sign in credentials from authenticator
			const cred: any = await navigator.credentials.get({
				publicKey: signinOptions,
			})

			// prepare credentials challenge response
			let response

			if (cred.response) {
				const clientDataJSON = base64url.encode(cred.response.clientDataJSON)
				const authenticatorData = base64url.encode(cred.response.authenticatorData)
				const signature = base64url.encode(cred.response.signature)
				const userHandle = base64url.encode(cred.response.userHandle)

				response = { clientDataJSON, authenticatorData, signature, userHandle }
			}

			await Auth.sendCustomChallengeAnswer(cognitoUserObject.current, JSON.stringify({ response }))

			return true
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const createWebAuthCredentials = async ({ username }: { username: string }) => {
		try {
			const credOptionsRequestData = {
				attestation: 'none',
				username,
				name: username,
				authenticatorSelection: {
					authenticatorAttachment: ['platform', 'cross-platform'],
					userVerification: 'preferred',
					requireResidentKey: false,
				},
			}

			const publicKeyChallenge = await API.post('adminApi', 'create-cred-request', {
				body: credOptionsRequestData,
			})

			const { challenge } = publicKeyChallenge

			publicKeyChallenge.user.id = base64url.decode(publicKeyChallenge.user.id)
			publicKeyChallenge.challenge = base64url.decode(publicKeyChallenge.challenge)

			const newUserCredentials: any = await navigator.credentials.create({
				publicKey: publicKeyChallenge,
			})

			if (null === newUserCredentials) {
				console.error('no user credentials')
				return
			}

			// parse credentials response to extract id and public-key, this is the information needed to register the user in Cognito
			const credential = {
				id: newUserCredentials.id,
				rawId: base64url.encode(newUserCredentials.rawId),
				type: newUserCredentials.type,
				challenge,
				response: {
					clientDataJSON: base64url.encode(newUserCredentials.response.clientDataJSON),
					attestationObject: base64url.encode(newUserCredentials.response.attestationObject),
				},
			}

			const { credId: id, publicKey } = await API.post('adminApi', 'parse-cred-response', { body: credential })

			return JSON.stringify({ id, publicKey })
		} catch (e) {
			console.error(e)
		}
	}

	const setupWebAuth = async (): Promise<
		boolean | AuthError | { type: 'web-auth-device-replaced'; successful: true }
	> => {
		try {
			const currentUser = await Auth.currentAuthenticatedUser()
			const publicKeyCredentials = await createWebAuthCredentials(currentUser)

			await Auth.updateUserAttributes(currentUser, {
				'custom:publicKeyCred': publicKeyCredentials
					? Buffer.from(publicKeyCredentials, 'utf8').toString('base64')
					: undefined,
			})

			if (true !== userInitialized) {
				return setUserDataAfterAuthentication()
			}

			return { type: 'web-auth-device-replaced', successful: true }
		} catch (e: any) {
			const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

			return error
		}
	}

	const confirmSignin = async ({ token }: { token?: number }): Promise<boolean | AuthError> => {
		try {
			if (undefined === cognitoUserObject.current) {
				const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

				return error
			}

			await Auth.confirmSignIn(
				cognitoUserObject.current, // Return object from Auth.signIn()
				String(token), // Confirmation code
				'SOFTWARE_TOKEN_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
			)

			return setUserDataAfterAuthentication()
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const sendPasswordResetEmail = async ({ username }: { username: string }) => {
		try {
			await Auth.forgotPassword(username)

			return true
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const confirmPasswordReset = async ({
		username,
		code,
		newPassword,
	}: {
		username: string
		code: string
		newPassword: string
	}) => {
		try {
			await Auth.forgotPasswordSubmit(username, code, newPassword)

			return true
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const completeNewPassword = async (submittedFields: { [key: string]: string }) => {
		try {
			if (undefined === cognitoUserObject.current) {
				const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

				return error
			}

			const { newPassword, confirmNewPassword, ...rest } = submittedFields

			const user = await Auth.completeNewPassword(
				cognitoUserObject.current, // the Cognito User Object
				newPassword,
				{
					...rest,
				}
			)

			cognitoUserObject.current = user

			const userAttributes = await Auth.userAttributes(user)
			const userhasConfiguredWebAuth = userAttributes.find((item) => 'custom:publicKeyCred' === item.Name)

			if (!userhasConfiguredWebAuth) {
				setWebAuthSetupAllowed(true)

				const error = { code: ErrorType.webAuthSetup, successful: false } as AuthError

				return error
			}

			if ('MFA_SETUP' === user.challengeName) {
				const error = { code: ErrorType.mfaSetup, successful: false } as AuthError
				cognitoUserObject.current = user

				return error
			}

			if ('SOFTWARE_TOKEN_MFA' === user.challengeName) {
				const error = { code: ErrorType.mfaSetup, successful: false } as AuthError
				cognitoUserObject.current = user

				return error
			}

			return setUserDataAfterAuthentication()
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	const setUserDataAfterAuthentication = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser()

			const updatedUserData = user.signInUserSession.idToken.payload

			const userGroups: string[] = updatedUserData['cognito:groups'] || []

			updatedUserData.groups = userGroups

			if (updatedUserData.groups.length === 0) {
				updatedUserData.groups = [UserGroup.None]
			}

			userData.current = updatedUserData

			cognitoUserObject.current = undefined

			setUserInitialized(true)
			setWebAuthSetupAllowed(true)

			return true
		} catch (e: any) {
			let error = { successful: false } as AuthError

			if ('string' === typeof e) {
				error.message = e
			} else {
				error = {
					...error,
					...e,
				}
			}

			return error
		}
	}

	const getTOTPSetupCode = async (): Promise<
		AuthError | { token: string; username: string; successful: boolean }
	> => {
		if (undefined === cognitoUserObject.current) {
			const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

			return error
		}

		const token = await Auth.setupTOTP(cognitoUserObject.current)

		return { token, username: cognitoUserObject.current?.username, successful: true }
	}

	const verifyTOTPSetup = async (submittedFields: { [key: string]: string }) => {
		if (undefined === cognitoUserObject.current) {
			const error = { code: ErrorType.sessionInvalid, successful: false } as AuthError

			return error
		}

		try {
			await Auth.verifyTotpToken(cognitoUserObject.current, String(submittedFields.token))

			await Auth.setPreferredMFA(cognitoUserObject.current, 'TOTP')

			return setUserDataAfterAuthentication()
		} catch (e: any) {
			const error = { ...e, successful: false } as AuthError

			return error
		}
	}

	useEffect(() => {
		const connectLogin = async () => {
			if ('true' !== CONNECT_ENABLED) {
				return
			}

			const connectAuthDetails = await getAuthDetails()

			if (!connectAuthDetails) {
				console.error('Unable to authorize with connect')
				return
			}

			await fetch(`${CONNECT_DOMAIN}/auth/sign-in`, {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'include',
				mode: 'no-cors',
				body: new URLSearchParams({
					credentials: `${JSON.stringify(connectAuthDetails.credentials)}`,
					destination: connectAuthDetails.destination,
				}),
			})

			setConnectInitialized(true)
		}

		if (canUseSoftphone === true) {
			connectLogin()
		}
	}, [getAuthDetails, canUseSoftphone])
	/**
	 * 	this effect makes sure that the current user is loaded again when reloading the page
	 */
	useEffect(() => {
		setUserDataAfterAuthentication()
		// eslint-disable-next-line
	}, [])

	// Return the user object and auth methods
	return {
		userData: userData.current,
		signin,
		signout,
		sendPasswordResetEmail,
		confirmPasswordReset,
		completeNewPassword,
		userInitialized,
		connectInitialized,
		getTOTPSetupCode,
		setupWebAuth,
		verifyTOTPSetup,
		confirmSignin,
		confirmWebAuth,
		createWebAuthCredentials,
		webAuthSetupAllowed,
	}
}
