import React from 'react'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { currencyFormat } from 'shared/helper/numberFormats'
import { RueckkonvertierbareRenteState } from '../PayoutModel'
import { Trans } from 'react-i18next'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import routesDictionary from 'routes'
import Checkbox from 'shared/components/Checkbox'
import Icon from 'shared/components/Icon'
import { IconType } from 'components/Icons'
import Button, { ButtonType } from 'shared/components/Button'

interface PayoutModelHeadProps {
	simulate: boolean // Determine which content to display
	showToggle: boolean
	userId?: string
	rueckkonvertierbareRente: RueckkonvertierbareRenteState
	handleToggleRente: () => void
	handleSubmitRueckkonvertierbareRente: (values: any) => void
	rueckkonvertierbareRenteMax?: number
	updatedData: any
}

const RueckkonvertierbareRenteForm = ({
	rueckkonvertierbareRente,
	handleToggleRente,
	handleSubmitRueckkonvertierbareRente,
	simulate,
	rueckkonvertierbareRenteMax,
	updatedData,
}: {
	rueckkonvertierbareRente: RueckkonvertierbareRenteState
	handleToggleRente: () => void
	handleSubmitRueckkonvertierbareRente: (values: any) => void
	simulate: boolean
	rueckkonvertierbareRenteMax?: number
	updatedData: any
}) => {
	const rueckkonvertierbareRenteFormField: FormFields = {
		rueckkonvertierbareRente: {
			fieldType: FormFieldType.text,
			type: TextInputType.maskedCurrency,
			label: 'Gewünschte Höhe der Rückk. Rente',
			value: simulate ? String(rueckkonvertierbareRente.simuliert) : String(rueckkonvertierbareRente.gewaehlt),
		},
	}

	return (
		<div>
			<h2>
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.rueckkonvertierbareRente.headline" />
			</h2>
			<Checkbox
				checked={rueckkonvertierbareRente.show}
				switch
				label={
					<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.rueckkonvertierbareRente.gewuenscht" />
				}
				onChange={handleToggleRente}
			/>
			{rueckkonvertierbareRente.show === true && (
				<div
					className="flex flex--align-items-start"
					style={{
						gap: '5px',
						backgroundColor: 'var(--color-light-grey--transparent)',
						width: 'fit-content',
						padding: 'var(--spacing)',
						marginTop: 'var(--spacing)',
					}}
				>
					<Icon type={IconType.wandlungEuro} className="margin--top" />

					<Form
						onSubmit={handleSubmitRueckkonvertierbareRente}
						fields={rueckkonvertierbareRenteFormField}
						submitButtonType={[ButtonType.secondary, ButtonType.medium]}
						updatedValues={updatedData}
						submitLabel={
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.tabelleNeuBerechnen" />
						}
						promiseTracker={{
							area: simulate ? 'updateAustrittsAuszahlungsmodell' : 'confirmedAuszahlungPostRequest',
						}}
					/>
				</div>
			)}
		</div>
	)
}

const PayoutModelHead: React.FC<PayoutModelHeadProps> = ({
	simulate,
	showToggle,
	userId,
	rueckkonvertierbareRente,
	rueckkonvertierbareRenteMax,
	handleToggleRente,
	handleSubmitRueckkonvertierbareRente,
	updatedData,
}) => {
	const { navigateTo, getChildPath } = useRouteHelper()
	return (
		<>
			<div
				className={
					simulate
						? 'payoutModelSimulieren__topInfo margin--bottom'
						: 'payoutModel__topInfo margin--bottom flex flex--direction-column font-size-medium'
				}
			>
				<div className="flex flex--align-items-center font-size-medium flex--gap">
					<Icon type={IconType.alert} />
					<div className="font-size-m topInfo--text">
						<Trans
							i18nKey={
								simulate
									? 'component.confirmedAuszahlungOption.modell.simulate.topInfoSimulate'
									: 'component.confirmedAuszahlungOption.modell.simulate.topInfoShow.first'
							}
						/>
					</div>
				</div>

				{!simulate && (
					<div>
						<div className="font-size-l topInfo--text">
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.simulate.topInfoShow.second" />
						</div>
					</div>
				)}
				{!simulate && userId && (
					<Button
						type={[ButtonType.highlight]}
						className="payoutModel__simulateButton"
						onClick={() => {
							const austrittPath = getChildPath(routesDictionary.austritte, 'austritt', {
								main: [userId],
							})
							navigateTo(austrittPath, true, { view: 'auszahlungsmodell-simulieren' })
							setTimeout(() => {
								navigateTo(austrittPath, true, {})
							})
						}}
					>
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.simulate.topInfoShow.button" />
					</Button>
				)}
			</div>

			{showToggle && (
				<RueckkonvertierbareRenteForm
					updatedData={updatedData}
					handleSubmitRueckkonvertierbareRente={handleSubmitRueckkonvertierbareRente}
					handleToggleRente={handleToggleRente}
					simulate={simulate}
					rueckkonvertierbareRente={rueckkonvertierbareRente}
					rueckkonvertierbareRenteMax={rueckkonvertierbareRenteMax}
				/>
			)}

			<h2 className="payoutModel__headline">
				<Trans i18nKey="component.confirmedAuszahlungOption.leistungsart.label" />:
			</h2>
		</>
	)
}

export default PayoutModelHead
