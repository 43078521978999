import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import { Notizen } from './Notes'
import dateFormat from 'shared/helper/dateFormat'
import { Auszahlungsdatum } from './SubComponents/Auszahlungsdatum'
import { Bankverbindung } from './SubComponents/Bankverbindung'

const Austrittsdaten: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()

	const { data: stammdaten } = useQuery(
		[QueryKey.austrittsbearbeitungen, { userId }],
		api.getAustrittsbearbeitungenStammdatenByIdentNumber,
		{
			enabled: !!userId,
		}
	)
	const {
		data: auszahlungsdatum,
		status,
		refetch: refetchAuszahlungsdatum,
	} = useQuery([QueryKey.pensionerAuszahlungsdatum, { userId }], api.getMasterDataAuszahlungsdatum, {
		enabled: !!userId,
	})
	const { data: versorgungsguthaben } = useQuery(['assets', { userId }], api.getAustrittVersorgungsguthaben, {
		enabled: !!userId,
		cacheTime: 0,
		staleTime: 0,
	})
	const { data: auszahlungsoption } = useQuery(['payoutModel', { userId }], api.getAustrittsAuszahlungsmodell, {
		enabled: !!userId,
	})
	const underThreshold = versorgungsguthaben?.istUnterAuszahlungsartWahlGrenze
	const modell = auszahlungsoption?.auszahlungsModell
	const includesLumpSum =
		modell &&
		(modell.type === 'lumpSum' ||
			(modell.type === 'mixed' && (modell.absoluteAmounts?.lumpSum || modell.distribution?.lumpSum)))
	const hasEinmalzahlung = underThreshold || includesLumpSum
	const isLeistungsfall = stammdaten?.fallart === 'LEISTUNGSFALL'

	const fields = useMemo(() => {
		const data = [
			[
				{
					headline: 'fallart',
					value: stammdaten?.fallart,
				},

				{
					headline: 'HR-System',
					value: stammdaten?.hrSystem,
				},
				{ headline: 'vo', value: stammdaten?.versorgungsordnung },
			],
			[
				{
					headline: 'Zusagedatum',
					value: stammdaten?.zusageDatum && dateFormat(new Date(stammdaten.zusageDatum)),
				},
				{
					headline: 'Austrittsdatum',
					value: stammdaten?.austrittsdatum && dateFormat(new Date(stammdaten.austrittsdatum)),
				},
			],
			[
				{
					headline: 'austrittsgrund',
					value: stammdaten?.austrittsgrund,
				},
			],
		]

		return data.map((v, i) => {
			return (
				<div className="austrittsbearbeitungen__sub-section " key={`subsection-austrittsdaten-${i}`}>
					{v.map((field, i2) => (
						<dl key={`stammdaten-field-${i}-${i2}`}>
							<dt className="bold-small-heading">
								<Trans
									i18nKey={`view.pensionerProfile.tabs.austrittsDaten.fields.${field.headline}`}
								></Trans>
							</dt>
							<dd>{field.value || '-'}</dd>
						</dl>
					))}
				</div>
			)
		})
	}, [stammdaten])

	return (
		<div className="austrittsbearbeitungen-austrittsdaten">
			{fields}
			{status === 'success' && isLeistungsfall && hasEinmalzahlung && (
				<Auszahlungsdatum
					auszahlungsdatum={auszahlungsdatum}
					refetchAuszahlungsdatum={refetchAuszahlungsdatum}
				/>
			)}
			{userId && isLeistungsfall && <Bankverbindung userId={userId}></Bankverbindung>}
			<Notizen />
		</div>
	)
}

export default Austrittsdaten
