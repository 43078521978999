import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import useApi, {
	AustrittsAuszahlungsOptionenResponse,
	AustrittsbearbeitungsSimulationRequest,
	AustrittsbearbeitungsSimulationResponse,
	QueryKey,
} from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import Form, { FormFieldType, FormFields, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat } from 'shared/helper/numberFormats'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import { components } from 'types/api-interface'
import '../../../styles/components/PayoutModel.css'
import { PayoutModelForm } from './SubComponents/PayoutModelForm'
import PayoutModelHead from './SubComponents/PayoutModelHead'
import zfToast from 'shared/components/ZFToast'

interface Zwischensummen {
	sum1: number
	sum2: number
	finalsum: number
}

type PayoutModelProps = {
	simulate: boolean
	showToggle: boolean
	gewaehlterBetrag?: number | null
	rueckkonvertierbareRenteMax: number
	auszahlungsoption?: AustrittsAuszahlungsOptionenResponse
}
export type RueckkonvertierbareRenteState = {
	simuliert: number | string
	gewaehlt: number | string
	show: boolean
}
type Ratenzahlungsplan = AustrittsbearbeitungsSimulationResponse['ratenzahlungsplan']
type SimulationMitGesamtguthaben = AustrittsbearbeitungsSimulationResponse & { gesamtguthaben: number }
export type TAuszahlungsmodellProperty = {
	hasEinmalzahlung: boolean
	has10Jahresraten: boolean
	has20Jahresraten: boolean
}
export interface ActiveFields {
	is10YearsActive: boolean
	is20YearsActive: boolean
	isLumpSumActive: boolean
}
type TLeistungsArtType = NonNullable<AustrittsAuszahlungsOptionenResponse['leistungsart']>['type']
export type TAuszahlungsModellType = NonNullable<AustrittsAuszahlungsOptionenResponse['auszahlungsModell']>['type']

function isLAFormFieldHidden(leistungsArtType: TLeistungsArtType, fieldName: string): boolean {
	const availableLeistungsArtFields: { [key in TLeistungsArtType]?: string[] } = {
		Erwerbsminderung: ['leistungsart', 'erwerbsminderungsType', 'payoutEmployerContribution'],
		Ruhestand: ['leistungsart', 'austrittsdatum'],
		VorgezogenerRuhestand: ['leistungsart', 'austrittsdatum'],
	}

	const fields = availableLeistungsArtFields[leistungsArtType] || []

	return !fields.includes(fieldName)
}

const PayoutModel: React.FC<
	PayoutModelProps & { austrittsDatum?: components['schemas']['AustrittStammdaten']['austrittsdatum'] }
> = ({ simulate, austrittsDatum, showToggle, rueckkonvertierbareRenteMax, gewaehlterBetrag, auszahlungsoption }) => {
	const [activeFields, setActiveFields] = useState<ActiveFields>({
		is10YearsActive: true,
		is20YearsActive: true,
		isLumpSumActive: true,
	})

	const [selectedPayoutModel, setSelectedPayoutModel] = useState<TAuszahlungsModellType>('mixed')

	const { userId } = useUserRecord()
	const formRef = useRef<FormRefActions>(null)
	const queryClient = useQueryClient()
	const api = useApi()

	const { mutateAsync: updateRente } = useMutation({
		mutationKey: QueryKey.confirmedAuszahlungPost,
		mutationFn: api.postConfirmedAuszahlung,
		onSuccess: () => {
			queryClient.refetchQueries([QueryKey.payoutModel, { userId }])
			queryClient.refetchQueries([QueryKey.confirmedAuszahlungGet, { userId }])
		},
	})

	const { mutateAsync: updateAustrittsAuszahlungsmodell } = useMutation({
		mutationKey: QueryKey.payoutModel,
		mutationFn: api.updateAustrittsAuszahlungsmodell,
	})

	const [updatedData, setUpdatedData] = useState({})
	const [updatedRatenzahlungsplan, setUpdatedRatenzahlungsplan] = useState<Ratenzahlungsplan>({})
	const [model, setModel] = useState<TAuszahlungsmodellProperty>()

	const [rueckkonvertierbareRente, setRueckkonvertierbareRente] = useState<RueckkonvertierbareRenteState>({
		simuliert: gewaehlterBetrag ?? 0,
		gewaehlt: gewaehlterBetrag ?? 0,
		show: !!gewaehlterBetrag && gewaehlterBetrag !== 0,
	})

	const simulatedData = ({
		rueckkonvertierbareRente,
		result,
		updatedSummen,
	}: {
		rueckkonvertierbareRente: number | string
		result: any
		updatedSummen: any
	}) => {
		const auszahlungen = result.auszahlungen || {}
		return {
			rueckkonvertierbareRente: currencyFormat(rueckkonvertierbareRente),
			rueckkonvertierbareRenteMonatlich: currencyFormat(auszahlungen.reconvertiblePension?.monatlicheRente),
			lumpSumTotal: currencyFormat(auszahlungen.lumpSum?.gesamtBetrag),
			totalAmountInstalments10Years: currencyFormat(auszahlungen.instalments10Years?.gesamtBetrag),
			instalments10YearsRatePerYear: currencyFormat(auszahlungen.instalments10Years?.jaehrlicheRate),
			amountFirstInstalment10Years: currencyFormat(auszahlungen.instalments10Years?.ersteRate),
			totalAmountInstalments20Years: currencyFormat(auszahlungen.instalments20Years?.gesamtBetrag),
			instalments20YearsRatePerYear: currencyFormat(auszahlungen.instalments20Years?.jaehrlicheRate),
			amountFirstInstalment20Years: currencyFormat(auszahlungen.instalments20Years?.ersteRate),
			lumpSumVerzinst: currencyFormat(auszahlungen.lumpSum?.ersteRate),
			remainingBalance: currencyFormat(updatedSummen?.sum1),
			remainingBalance2: currencyFormat(updatedSummen?.sum2),
			remainingBalanceFinal: currencyFormat(updatedSummen?.finalsum),
		}
	}

	useEffect(() => {
		if (!auszahlungsoption) return
		const updatedSummen = auszahlungsoption
			? sumCalculation(
					auszahlungsoption,
					simulate ? Number(rueckkonvertierbareRente.simuliert) : gewaehlterBetrag
			  )
			: undefined

		setUpdatedData((prev) => ({
			...prev,
			...simulatedData({
				result: auszahlungsoption,
				rueckkonvertierbareRente: simulate ? rueckkonvertierbareRente.simuliert : gewaehlterBetrag ?? 0,
				updatedSummen,
			}),
		}))
	}, [auszahlungsoption, gewaehlterBetrag, rueckkonvertierbareRente.simuliert, simulate])

	const handleSubmitRueckkonvertierbareRente = async (values: any) => {
		if (sanitizeDecimalNumber(values.rueckkonvertierbareRente) > rueckkonvertierbareRenteMax) {
			zfToast({
				type: 'error',
				title: 'Maximaler Betrag der rückkonvertierbaren Rente überschritten',
				content: `Der maximal mögliche Betrag für die rückkonvertierbare Rente beträgt ${currencyFormat(
					rueckkonvertierbareRenteMax
				)}`,
			})
			return { successfull: false }
		}
		const updateRueckkonvertierbareRente = (key: string) => {
			setRueckkonvertierbareRente((prev) => ({
				...prev,
				[key]: sanitizeDecimalNumber(values.rueckkonvertierbareRente),
			}))
		}

		if (!simulate) {
			updateRueckkonvertierbareRente('gewaehlt')
			try {
				await updateRente({
					userId,
					body: { rueckkonvertierungsBetrag: sanitizeDecimalNumber(values.rueckkonvertierbareRente) },
				})
			} catch (e) {
				console.log(e)
			}
		} else {
			updateRueckkonvertierbareRente('simuliert')
			// Timeout is needed to ensure that the form is submitted after the state has been updated
			setTimeout(() => {
				formRef.current?.submitForm()
			})
		}

		return { successfull: true }
	}

	const handleToggleRente = async () => {
		setRueckkonvertierbareRente((prev) => ({
			...prev,
			show: !prev.show,
		}))

		if (simulate && rueckkonvertierbareRente.show === true && rueckkonvertierbareRente.simuliert !== 0) {
			setRueckkonvertierbareRente((prev) => ({
				...prev,
				simuliert: 0,
			}))
			// Timeout is needed to ensure that the form is submitted after the state has been updated
			setTimeout(() => {
				formRef.current?.submitForm()
			})
		}

		if (!simulate && rueckkonvertierbareRente.show === true && rueckkonvertierbareRente.gewaehlt !== 0) {
			setRueckkonvertierbareRente((prev) => ({
				...prev,
				gewaehlt: 0,
			}))
			await updateRente({ userId, body: { rueckkonvertierungsBetrag: 0 } })
		}
	}

	useEffect(() => {
		if (!auszahlungsoption) {
			return
		}
		const model = auszahlungsoption.auszahlungsModell?.type
		model && setSelectedPayoutModel(model)
		setUpdatedRatenzahlungsplan(auszahlungsoption.ratenzahlungsplan)
		const istEinmalzahlung = model === 'lumpSum'
		const ist10Jahresraten = model === 'instalments10Years'
		const ist20Jahresraten = model === 'instalments20Years'
		const istMixed = model === 'mixed'
		const istMixedMitEinmalzahlung =
			istMixed &&
			(!!auszahlungsoption.auszahlungsModell?.distribution?.lumpSum ||
				!!auszahlungsoption.auszahlungsModell?.absoluteAmounts?.lumpSum)
		const istMixedMit10Jahresraten =
			istMixed &&
			(!!auszahlungsoption.auszahlungsModell?.distribution?.instalments10Years ||
				!!auszahlungsoption.auszahlungsModell?.absoluteAmounts?.instalments10Years)
		const istMixedMit20Jahresraten =
			istMixed &&
			(!!auszahlungsoption.auszahlungsModell?.distribution?.instalments20Years ||
				!!auszahlungsoption.auszahlungsModell?.absoluteAmounts?.instalments20Years)
		const auszahlungsmodellProperty: TAuszahlungsmodellProperty = {
			hasEinmalzahlung: !!(istEinmalzahlung || istMixedMitEinmalzahlung),
			has10Jahresraten: !!(ist10Jahresraten || istMixedMit10Jahresraten),
			has20Jahresraten: !!(ist20Jahresraten || istMixedMit20Jahresraten),
		}
		setModel(auszahlungsmodellProperty)
	}, [auszahlungsoption])

	function handleFieldActive(field: keyof ActiveFields, option: string) {
		setActiveFields((prevFields) => ({
			...prevFields,
			[field]: option === 'ja',
		}))
	}

	function handleSelectedPayoutModelChange(value: TAuszahlungsModellType) {
		setSelectedPayoutModel(value)
	}

	const handleOnSubmit = async (submittedFields: any) => {
		if (!userId || !auszahlungsoption) {
			return
		}

		let body: AustrittsbearbeitungsSimulationRequest = {
			leistungInfo: {
				relevantesVersorgungsguthaben: auszahlungsoption.gesamtguthaben,
				eintrittLeistungsfall: auszahlungsoption?.datumErsteRate,
				auszahlungsModell: {
					type: selectedPayoutModel,
					absoluteAmounts: {
						instalments10Years: activeFields.is10YearsActive
							? sanitizeDecimalNumber(submittedFields.totalAmountInstalments10Years)
							: null,
						instalments20Years: activeFields.is20YearsActive
							? sanitizeDecimalNumber(submittedFields.totalAmountInstalments20Years)
							: null,
						lumpSum: activeFields.isLumpSumActive
							? sanitizeDecimalNumber(submittedFields.lumpSumTotal)
							: null,
						reconvertiblePension: rueckkonvertierbareRente.show
							? Number(rueckkonvertierbareRente.simuliert)
							: null,
					},
				},
			},
			employeeInfo: { pensionRuleSetCode: 'VO20' },
		}

		try {
			const simulationResult = await updateAustrittsAuszahlungsmodell({ userId, body })
			setUpdatedRatenzahlungsplan(simulationResult.ratenzahlungsplan)

			const { gesamtguthaben } = auszahlungsoption
			const combinedData: SimulationMitGesamtguthaben = { ...simulationResult, gesamtguthaben }
			const updatedSummen = sumCalculation(combinedData, Number(rueckkonvertierbareRente.simuliert))

			setUpdatedData({
				...simulatedData({
					rueckkonvertierbareRente: rueckkonvertierbareRente.simuliert,
					result: simulationResult,
					updatedSummen,
				}),
			})

			return { successfull: true }
		} catch (error) {
			console.error('POST request failed', error)
		}
	}

	// Summen Kalkulation //
	function sumCalculation(
		auszahlungsoption: SimulationMitGesamtguthaben,
		rente: number | null | undefined
	): Zwischensummen | undefined {
		const { gesamtguthaben, auszahlungen } = auszahlungsoption
		const { instalments10Years, instalments20Years, lumpSum } = auszahlungen

		if (rente == null) {
			return
		}

		const sum1 = gesamtguthaben - rente - (instalments10Years?.gesamtBetrag ?? 0)

		const sum2 = sum1 - (instalments20Years?.gesamtBetrag ?? 0)

		const finalsum = sum2 - (lumpSum?.gesamtBetrag ?? 0)

		const limitDecimals = (sum: number) => parseFloat(sum.toFixed(2))

		return {
			sum1: limitDecimals(sum1),
			sum2: limitDecimals(sum2),
			finalsum: limitDecimals(finalsum),
		}
	}

	const summen = useMemo(() => {
		if (!auszahlungsoption) return undefined
		return sumCalculation(
			auszahlungsoption,

			simulate ? Number(rueckkonvertierbareRente.simuliert) : gewaehlterBetrag
		)
	}, [auszahlungsoption, gewaehlterBetrag, rueckkonvertierbareRente.simuliert, simulate])

	const leistungsartFields = useMemo(() => {
		const leistungsArtType = auszahlungsoption?.leistungsart?.type

		const isErwerbsminderung = leistungsArtType === 'Erwerbsminderung'
		const isRuhestand = leistungsArtType === 'Ruhestand'
		const isVorgezogenerRuhestand = leistungsArtType === 'VorgezogenerRuhestand'
		const isKindOfRuhestand = isRuhestand || isVorgezogenerRuhestand

		if (!auszahlungsoption || leistungsArtType === undefined) {
			return {}
		}

		const getActualAustrittsdatum = (
			dateFromValue: string,
			austrittsDatum?: components['schemas']['AustrittStammdaten']['austrittsdatum']
		) => {
			if (!austrittsDatum) {
				return dateFromValue
			}
			const formatedAustrittsDatum = dateFormat(new Date(austrittsDatum))
			if (formatedAustrittsDatum !== dateFromValue) {
				return formatedAustrittsDatum
			}
			return dateFromValue
		}

		const fields: FormFields = {
			leistungsart: {
				fieldType: simulate ? FormFieldType.select : FormFieldType.text,
				type: simulate ? undefined : TextInputType.text,
				alternativeStyle: simulate ? true : undefined,
				options: simulate
					? [
							{
								value: 'VorgezogenerRuhestand',
								label: t(
									'component.confirmedAuszahlungOption.leistungsart.types.VorgezogenerRuhestand'
								),
							},
							{
								value: 'Ruhestand',
								label: t('component.confirmedAuszahlungOption.leistungsart.types.Ruhestand'),
							},
							{
								value: 'Erwerbsminderung',
								label: t('component.confirmedAuszahlungOption.leistungsart.types.Erwerbsminderung'),
							},
					  ]
					: [],
				value: t<string>(
					`component.confirmedAuszahlungOption.leistungsart.types.${auszahlungsoption.leistungsart?.type}`
				),
				label: <Trans i18nKey="component.confirmedAuszahlungOption.leistungsart.austrittsgrund" />,
				disabled: true,
				hidden: isLAFormFieldHidden(leistungsArtType, 'leistungsart'),
			},
			erwerbsminderungsType: {
				fieldType: simulate ? FormFieldType.select : FormFieldType.text,
				type: simulate ? undefined : TextInputType.text,
				alternativeStyle: simulate ? true : undefined,
				options: simulate
					? [
							{
								value: 'befristetVoll',
								label: t<string>(
									'component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.befristetVoll'
								),
							},
							{
								value: 'unbefristetVoll',
								label: t<string>(
									'component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.unbefristetVoll'
								),
							},
							{
								value: 'befristetTeilweise',
								label: t<string>(
									'component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.befristetTeilweise'
								),
							},
							{
								value: 'unbefristetTeilweise',
								label: t<string>(
									'component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.unbefristetTeilweise'
								),
							},
					  ]
					: [],
				value: t<string>(
					`component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.${
						isErwerbsminderung ? auszahlungsoption.leistungsart?.erwerbsminderungType : ''
					}`
				),
				label: <Trans i18nKey="component.confirmedAuszahlungOption.leistungsart.erwerbsminderungType" />,
				disabled: true,
				hidden: isLAFormFieldHidden(leistungsArtType, 'erwerbsminderungsType'),
			},
			austrittsdatum: {
				fieldType: FormFieldType.text,
				type: TextInputType.text,
				value: isKindOfRuhestand
					? getActualAustrittsdatum(
							dateFormat(new Date(auszahlungsoption?.leistungsart?.austrittsdatum || '')),
							austrittsDatum
					  )
					: '',
				// dateFormat(
				// 	new Date(isKindOfRuhestand ? auszahlungsoption?.leistungsart?.austrittsdatum || '' : '')
				// ),
				label: <Trans i18nKey="component.confirmedAuszahlungOption.leistungsart.austrittsdatum" />,
				disabled: true,
				hidden: isLAFormFieldHidden(leistungsArtType, 'austrittsdatum'),
			},
			payoutEmployerContribution: {
				fieldType: simulate ? FormFieldType.select : FormFieldType.text,
				type: simulate ? undefined : TextInputType.text,
				alternativeStyle: simulate ? true : undefined,
				options: [
					{ value: 'ja', label: t<string>('component.confirmedAuszahlungOption.ja') },
					{ value: 'nein', label: t<string>('component.confirmedAuszahlungOption.nein') },
				],
				value: (isErwerbsminderung ? auszahlungsoption?.leistungsart?.payoutEmployeeContribution : false)
					? t<string>('component.confirmedAuszahlungOption.ja')
					: t<string>('component.confirmedAuszahlungOption.nein'),
				label: <Trans i18nKey="component.confirmedAuszahlungOption.leistungsart.anfinanziertesguthaben" />,
				disabled: true,
				hidden: isLAFormFieldHidden(leistungsArtType, 'payoutEmployerContribution'),
			},
		}

		return fields
	}, [auszahlungsoption, simulate])

	/**
	 *  determines wether the initial value or the simulated value has to be checked for formatting
	 * @returns
	 */

	const getFinalBalanceClass = useCallback(() => {
		if (!simulate) {
			return 'payoutModel__final-balance'
		}

		const finalSum =
			Object.keys(updatedData).length > 0 ? (updatedData as any)?.remainingBalanceFinal : summen?.finalsum

		/**
		 * currencyFormat adds an invisible character between number and currency symbol,
		 * therefore do not remove the space inside the check or replace it with a regular space
		 */
		if (sanitizeDecimalNumber(finalSum) === 0) {
			return 'payoutModelSimulieren__final-balance'
		} else {
			return 'payoutModelSimulieren__final-balance-error'
		}
	}, [simulate, updatedData, summen])

	const noAuszahlungsmodell = auszahlungsoption?.error?.includes('noAuzahlungsmodell')

	if (noAuszahlungsmodell && !simulate) {
		return (
			<div className="margin margin--top">
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.noAuszahlungsmodell" />
			</div>
		)
	}

	if (!auszahlungsoption) {
		return (
			<div className="margin margin--top">
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.simulate.error" />
			</div>
		)
	}

	const tableHeaderPayoutPlan = (
		<GridListRow>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutPlan.instalmentNumber" />
			</GridListCell>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutPlan.payoutDate" />
			</GridListCell>
			<GridListCell className={'grid-list__cell--header bold-small-heading'}>
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutPlan.payoutValue1%" />
			</GridListCell>
		</GridListRow>
	)

	const zahlungsplan = Object.keys(updatedRatenzahlungsplan).length > 0 ? updatedRatenzahlungsplan : undefined

	const zahlungsPlanTables =
		zahlungsplan &&
		Object.entries(zahlungsplan || []).map((zahlung, index) => {
			return (
				<div key={'payout-table--' + index}>
					<h3>
						<Trans i18nKey={`component.confirmedAuszahlungOption.modell.payoutOptions.${zahlung[0]}`} />
					</h3>
					<GridList columnCount={3} className="tab-numbers">
						{tableHeaderPayoutPlan}
						{zahlung[1].map((zahlung, index) => (
							<GridListRow key={index}>
								<GridListCell className="numeric flex--justify-content-end">{index + 1}</GridListCell>
								<GridListCell className="numeric flex--justify-content-end">
									{dateFormat(new Date(zahlung.datum))}
								</GridListCell>
								<GridListCell className="numeric flex--justify-content-end">
									{currencyFormat(zahlung.betrag)}
								</GridListCell>
							</GridListRow>
						))}
					</GridList>
				</div>
			)
		})

	const payoutModelHeader = (
		<div className="payoutModelHeader__container">
			<div className="payoutModelHeader__caption bold-small-heading">
				<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.versorgungsGuthabenEintrittLeistungsfall" />
			</div>
			<div className="payoutModeHeader__gesamtGuthaben bold-heading">
				{currencyFormat(auszahlungsoption?.gesamtguthaben)}
			</div>
		</div>
	)

	return (
		<div className="fit-content-width margin--top">
			<PayoutModelHead
				handleSubmitRueckkonvertierbareRente={handleSubmitRueckkonvertierbareRente}
				handleToggleRente={handleToggleRente}
				showToggle={showToggle}
				simulate={simulate}
				rueckkonvertierbareRente={rueckkonvertierbareRente}
				rueckkonvertierbareRenteMax={rueckkonvertierbareRenteMax}
				userId={userId}
				updatedData={updatedData}
			/>
			{leistungsartFields && (
				<Form fields={leistungsartFields} hideSubmit={true} onSubmit={() => {}} className="leistungsArt"></Form>
			)}

			<h2 className="payoutModel__headline">
				<Trans
					i18nKey={`component.confirmedAuszahlungOption.modell.payoutOptions.${
						simulate ? 'simulatePayoutOptions' : 'viewPayoutOptions'
					}`}
				/>
				:
			</h2>

			{model && (
				<div className="payoutModel__wrapper">
					<div>
						<h2 className="payoutModel__subheadline">
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.payoutModelOptions" />
						</h2>
						{payoutModelHeader}
						{auszahlungsoption && (
							<PayoutModelForm
								activeFields={activeFields}
								showToggle={showToggle}
								auszahlungsoption={auszahlungsoption}
								handleFieldActive={handleFieldActive}
								handleSelectedPayoutModelChange={handleSelectedPayoutModelChange}
								model={model}
								selectedPayoutModel={selectedPayoutModel}
								simulate={simulate}
								getFinalBalanceClass={getFinalBalanceClass}
								onSubmit={handleOnSubmit}
								ref={formRef}
								rueckkonvertierbareRente={rueckkonvertierbareRente}
								summen={summen}
								updatedData={updatedData}
								rueckkonvertierbareRenteMax={rueckkonvertierbareRenteMax}
							/>
						)}
					</div>
					<div>
						<h2 className="payoutModel__subheadline">
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.payoutPlan" />
						</h2>
						{zahlungsPlanTables?.length ? (
							zahlungsPlanTables
						) : (
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.noPayoutPlan" />
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default PayoutModel
